<template>
  <v-container>
    <BaseForm
      :title="$route.meta.title"
      :loading="loading"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row>
          <!-- LANGUAGE -->
          <!-- <v-col cols="12">
            <ValidationProvider
              name="Idioma"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                label="Idioma *"
                item-value="id"
                item-text="name"
                v-model="translation.language_id"
                :items="languages"
                :error-messages="errors[0]"
                dense
                autofocus
              />
            </ValidationProvider>
            {{this.translation.language_id}}
          </v-col> -->
          <v-col cols="12">
            <ValidationProvider
              name="Cadena"
              rules="required|alpha_dash"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Cadena *"
                placeholder="Cadena a traducir"
                v-model="translation.label"
                dense
                :error-messages="errors[0]"
                @input="handleDisabledTranslation"
              />
            </ValidationProvider>
          </v-col>
          <!-- TRANSLATION -->
          <v-col cols="12">
            <ValidationProvider
              name="Traducción"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Traducción *"
                placeholder="Traducción de la cadena"
                v-model="translation.translation"
                :maxlength="maxLengthTranslation"
                dense
                :error-messages="errors[0]"
                :disabled="disabled_translation"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import LanguageService from '@/services/language'
import TranslationService from '@/services/translation'

export default {
  components: { BaseForm },
  data() {
    return {
      loading: false,
      languages: [],
      translation: {},
      disabled_translation: true
    }
  },
  async created() {
    this.loading = true
    try {
      await this.populateLanguages()
      // const id = this.$route.params.id
      // if (id) {
      //   this.translation = await TranslationService.find(id)
      // }
      this.translation.language_id = this.$route.params.id
    } catch (error) {
      console.log(error)
      this.$dialog.message.error('No se pudo cargar el registro.')
    } finally {
      this.loading = false
    }
  },
  methods: {
    async populateLanguages() {
      const { data } = await LanguageService.fetch()
      this.languages = data.data
    },
    async handleSubmit() {
      try {
        await TranslationService.save(this.translation)
        this.$dialog.notify.success('Registro guardado con éxito.')
        this.$router.go(-1)
      } catch { 
        this.$dialog.message.error('No se pudo guardar el registro.')
      }
    },
    handleDisabledTranslation() {
      this.translation.label ? this.disabled_translation = false : this.disabled_translation = true
      this.translation.label ? this.translation.translation : this.translation.translation = ''

    }
  },
  computed: {
    maxLengthTranslation() {
      if (this.translation.label && this.translation.label.length > 0) {
        return `${this.translation.label.length + 5}`
      } else {
        return `0`  
      }
    }
  }
}
</script>
