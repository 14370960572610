<template>
  <BaseForm :title="titleHandler" @submit="handleSubmit" :loading="loading">
    <template #content>
      <v-tabs center-active v-model="tab" @submit="handleSubmit">
        <v-tab v-for="category in categories" :key="category.index">
          {{ category }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="category in categories" :key="category.index">
          <translation-input
            :translations="translationsHandler(category)"
            :labels="labelsHandler(category)"
          />
        </v-tab-item>
      </v-tabs-items>
    </template>
  </BaseForm>
</template>
<script>
import LanguageService from '@/services/language'
import TranslationService from '@/services/translation'
import BaseForm from '@/components/commons/BaseForm'
import TranslationInput from '@/views/general/languages/translations/TranslationInput'

export default {
  components: { BaseForm, TranslationInput },
  data() {
    return {
      loading: false,
      search: '',
      categories: [
        'Login',
        'Menu',
        'Portal de Consulta',
        'Transferencia',
        'Botones',
        'Correos',
        'Mantenimiento'
      ],
      language: {},
      translations: [],
      translationsSpanish: [],
      tab: null
    }
  },
  async created() {
    await this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      try {
        const data = await LanguageService.find(this.$route.params.id, {
          include: 'translations'
        })
        this.language = data
        this.translations = this.language.translations
        const spanish = await LanguageService.find(2, {
          include: 'translations'
        })
        this.translationsSpanish = spanish.translations
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },
    async handleSubmit() {
      this.loading = true
      try {
        await TranslationService.post_collection(this.translations)
        this.$dialog.notify.success('Parámetros guardados con éxito.')
      } catch (e) {
        if (e.response.status !== 401) this.$dialog.notify.error(e.message)
      } finally {
        this.loading = false
      }
    },
    translationsHandler(category) {
      const filtered = this.translations.filter(
        translation => translation.category === category
      )
      return filtered.sort((a, b) => (a.translation > b.translation ? 1 : -1))
    },
    labelsHandler(category) {
      const filtered = this.translationsSpanish.filter(
        translation => translation.category === category
      )
      return filtered.sort((a, b) => (a.translation > b.translation ? 1 : -1))
    }
  },
  computed: {
    titleHandler() {
      if (this.language.name === 'English') {
        return `Traducciones Inglés`
      } else {
        return `Traducciones ${this.language.name}`
      }
    }
  }
}
</script>

<style scoped>
.v-tab {
  text-transform: none !important;
  font-weight: bold;
}
</style>
