<template>
  <span class="mt-4">
    <v-row v-for="(t, index) in labels" :key="index" dense>
      <v-col cols="12">
        <ValidationProvider
          :name="spanishLabels(t.label)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            v-model="translations.find(el => el.label === t.label).translation"
            :label="spanishLabels(t.label)"
            :error-messages="errors[0]"
            maxlength="255"
          ></v-text-field>
        </ValidationProvider>
      </v-col>
    </v-row>
  </span>
</template>
<script>
export default {
  props: {
    translations: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    spanishLabels(itemLabel) {
      return this.labels.find(el => el.label === itemLabel).translation
    },
  }
}
</script>
<style scoped></style>
